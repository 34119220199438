<template>
  <b-container fluid>
    <div v-if="isLoaded && productBundleDetails">
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <h2>Editare set produse</h2>
        <b-button-toolbar class="pb-3">
          <super-button class="mx-1 p-2" id="navigateBackToolbarButton" :to="lastRoute || { name: 'productBundle' }"><i class="fas fa-caret-left"/>
            <span class="d-none d-lg-inline ml-1">Înapoi</span>
          </super-button>
        </b-button-toolbar>

        <b-tooltip target="navigateBackToolbarButton" placement="topleft" custom-class="d-lg-none">Înapoi</b-tooltip>
      </div>

      <!-- Product bundle details card -->
      <b-card>
        <div slot="header">
          Detalii set produse <strong>{{ productBundleDetails.codSetProduse }}</strong>&nbsp;
        </div>
        <div class="row">
          <b-form-group label="Cod set" class="col-xs-12 col-md-4 bundle_label_text_left">
            <b-input v-model="productBundleDetails.codSetProduse" readonly></b-input>
          </b-form-group>

          <b-form-group label="Denumire set" class="col-xs-12 col-md-6 bundle_label_text_left">
            <b-input v-model="productBundleDetails.denumire"></b-input>
          </b-form-group>

          <b-form-group label="Este activ" class="col-xs-12 col-md-2 bundle_label_text_left">
            <b-form-select v-model="productBundleDetails.esteActiv" :options="esteActivOptions"></b-form-select>
          </b-form-group>

          <b-form-group label="Descriere set" class="col-12 bundle_label_text_left">
            <b-form-textarea v-model="productBundleDetails.descriere" :rows="3" :max-rows="6"></b-form-textarea>
          </b-form-group>
        </div>
        <b-button class="btn btn-primary" v-on:click="saveHeader">Salveaza</b-button>
      </b-card>

      <br>

      <!-- Product bundle rows table -->
      <b-table
        show-empty
        small
        no-footer-sorting
        ref="productBundleLinesTable"
        empty-text="Setul nu are produse"
        :foot-clone="!currentlyEditedLine"
        :items="items"
        :fields="tableFields"
        :tbody-tr-class="rowClass"
        :busy.sync="tableBusy"
        class="tweaked-table">
        <template v-slot:cell(rowIndex)="row">
          {{ row.index + 1 }}.
        </template>
        <template v-slot:cell(productName)="row">
          <span v-if="!currentlyEditedLine || currentlyEditedLine.linieSetProduseID !== row.item.linieSetProduseID">{{ row.item.denumire }}</span>
          <multiselect
            v-if="currentlyEditedLine && currentlyEditedLine.linieSetProduseID === row.item.linieSetProduseID"
            ref="produsInternalID"
            id="produsInternalID"
            v-model="currentProductEntry"
            track-by="produsInternalID"
            no-result="Niciun rezultat nu corespunde"
            no-options="Caută după cod"
            placeholder="Caută după cod"
            select-label=""
            selected-label=""
            deselect-label=""
            :show-labels="false"
            :searchable="true"
            :options="currentProductBundleLineLookupValues"
            :loading="currentProductBundleLineLookupValuesLoading"
            :internal-search="false"
            :allow-empty="false"
            :options-limit="20"
            :limit="5"
            :limit-text="limitText"
            :tabindex="1"
            @search-change="asyncLookupProductBundleLines"
            @select="currentProductBundleLineSelected"
          >
            <template
              slot="noResult"
            >
              Nicio înregistrare găsită. Modifică criteriul de căutare.
            </template>
            <template
              slot="noOptions"
            >
              Nicio înregistrare disponibilă. Modifică criteriul de căutare.
            </template>
            <template
              slot="beforeList">
                <li class="multiselect__element font-weight-bold w-100 pr-0">
                  <span class="multiselect__option">
                    <div class="option__desc row">
                      <div class="option__small col-3">Categorie:</div>
                      <div class="option__small col-2">Cod:</div>
                      <div class="option__small col-5">Denumire:</div>
                      <div class="option__small col-1 text-right">Preț:</div>
                    </div>
                  </span>
                </li>
            </template>
            <template
              slot="singleLabel"
              slot-scope="props"
            >
              <div class="option__desc">
                <span class="option__title">{{ props.option.denumire }}</span>
              </div>
            </template>
            <template
              slot="option"
              slot-scope="props"
            >
              <div class="option__desc row">
                <div class="option__small col-3 overflow-hidden">{{ props.option.categorieProdus }}</div>
                <div class="option__small col-2 text-wrap text-break">{{ props.option.codProdus }}</div>
                <div class="option__small col-5 overflow-hidden text-wrap text-break">{{ props.option.denumire }}</div>
                <div class="option__small col-1 text-right">{{ props.option.pretReferinta }}</div>
              </div>
            </template>
          </multiselect>
        </template>

        <template v-slot:cell(unitPrice)="row">
          <span v-if="!currentlyEditedLine || currentlyEditedLine.linieSetProduseID !== row.item.linieSetProduseID">{{row.item.pretUnitar | displayAsDecimal(3)}}</span>
          <b-input-group v-if="currentlyEditedLine && currentlyEditedLine.linieSetProduseID === row.item.linieSetProduseID">
          <b-form-input
            class="text-right"
            ref="unitPrice"
            @focus="selectAll"
            @keyup="focusRef('editRowButton')"
            :readonly="!currentProductEntry"
            v-model="currentlyEditedLine.pretUnitar"
          />
          <template v-slot:prepend v-if="shouldShowPriceWarning && currentProductEntry">
            <b-input-group-text>
              <strong class="text-danger" v-if="shouldShowPriceWarning">
                <i class="fas fa-exclamation-triangle" v-b-tooltip title="Preț incorect!"/>
              </strong>
            </b-input-group-text>
          </template>
          </b-input-group>
        </template>

        <template v-slot:cell(quantity)="row">
        <span v-if="!currentlyEditedLine || currentlyEditedLine.linieSetProduseID !== row.item.linieSetProduseID">{{ row.item.cantitate | displayAsDecimal(3) }}</span>
        <b-input-group v-if="currentlyEditedLine && currentlyEditedLine.linieSetProduseID === row.item.linieSetProduseID">
          <b-form-input
            class="text-right"
            ref="quantity"
            @focus="selectAll"
            @keyup="focusRef('editRowButton')"
            :readonly="!currentProductEntry"
            v-model="currentlyEditedLine.cantitate"
          />
          <template v-slot:prepend v-if="shouldShowQuantityWarning && currentProductEntry">
            <b-input-group-text>
              <strong class="text-danger" v-if="shouldShowQuantityWarning">
                <i class="fas fa-exclamation-triangle" v-b-tooltip title="Cantitate incorectă!"/>
              </strong>
            </b-input-group-text>
          </template>
        </b-input-group>
        </template>

        <template v-slot:cell(totalValue)="row">
          <span v-if="currentlyEditedLine && currentlyEditedLine.linieSetProduseID === row.item.linieSetProduseID">
            <span v-if="!toBigNumber(currentlyEditedLine.cantitate).times(currentlyEditedLine.pretUnitar).isNaN()">{{ toBigNumber(currentlyEditedLine.cantitate).times(currentlyEditedLine.pretUnitar).dp(2).toString() }}</span>
          </span>
          <span v-else>{{ toBigNumber(row.item.cantitate).times(row.item.pretUnitar).dp(2).toString() }}</span>
        </template>

        <template v-slot:cell(actions)="row">
          <!-- View mode buttons -->
          <b-button-group v-if="!currentlyEditedLine">
            <super-button
              variant="primary"
              size="sm"
              :disabled="tableBusy || xhrRequestRunning"
              @click="beginEditLine(row)"
            >
              <i class="fas fa-edit" />
            </super-button>
            <b-button
              variant="danger"
              size="sm"
              :disabled="tableBusy || xhrRequestRunning"
              @click="deleteRow(row)"
            >
              <i class="fas fa-trash-alt" />
            </b-button>
          </b-button-group>
          <!-- Edit mode buttons -->
          <b-button-group v-if="currentlyEditedLine && currentlyEditedLine.linieSetProduseID === row.item.linieSetProduseID">
            <b-button
              ref="editRowButtonX"
              variant="success"
              size="sm"
              :disabled="tableBusy || xhrRequestRunning"
              @click="editRow(row)"
            >
              <i class="fas fa-check" />
            </b-button>
            <b-button
              v-shortkey="['ctrl', 'alt', 'c']"
              variant="danger"
              size="sm"
              :disabled="tableBusy || xhrRequestRunning"
              @click="produsInternalID(row)"
              @shortkey="produsInternalID(row)"
            >
              <i class="fas fa-times" />
            </b-button>
          </b-button-group>
        </template>

        <template v-slot:foot(totalValue)>
          {{ items.reduce((s, i) => s.plus(i.valoare), toBigNumber(0)).dp(2).toString() }}
        </template>

        <template v-slot:foot()>&nbsp;</template>

        <template v-slot:foot(actions)>
          <!-- Add row button -->
          <b-button
            id="addNewLine"
            variant="success"
            size="sm"
            @click="beginEditLine()"
            v-if="!currentlyEditedLine"
            :disabled="!!currentlyEditedLine || tableBusy || xhrRequestRunning"
            v-shortkey="['ctrl', 'alt', 'a']"
            @shortkey="beginEditLine()"
          >
            <span class="d-none d-lg-inline"><i class="fas fa-plus-square mr-1" /></span><span>Adaugă <b-badge variant="secondary" class="mt-1 d-block">Ctrl + Alt + A</b-badge></span>
          </b-button>
        </template>
      </b-table>
    </div>
  </b-container>
</template>

<style lang="scss">
.col-max-w-50px {
  max-width: 50px;
}

.col-min-w-250px {
  min-width: 250px;
}

.col-w-200px {
  width: 200px;
}

.col-numeric-value {
  width: 105px;
}

.bundle_label_text_left {
    text-align: initial;
}
</style>

<script>
import BigNumber from 'bignumber.js';
import { mapState, mapActions } from 'vuex';
import jump from 'jump.js';
import toasts from '@/services/toasts';

export default {
  name: 'ProductBundleDetails',
  components: {
  },
  data() {
    return {
      esteActivOptions: [
        { value: true, text: 'Da' },
        { value: false, text: 'Nu' },
      ],

      isLoaded: false,
      productBundleId: null,
      productBundleDetails: null,

      // Currently edited row object
      currentlyEditedLine: null,

      currentProductEntry: null,

      currentProductBundleLineLookupValues: [],
      currentProductBundleLineLookupValuesLoading: false,

      tableBusy: false,

      tableFields: [
        {
          key: 'rowIndex', label: '#', class: 'col-max-w-50px',
        },
        {
          key: 'productName', label: 'Produs', thClass: 'col-min-w-250px', class: 'col-min-w-250px text-left',
        },
        {
          key: 'quantity', label: 'Cantitate', thClass: 'col-numeric-value', class: 'text-right',
        },
        {
          key: 'unitPrice', label: 'Preț unitar', thClass: 'col-numeric-value', class: 'text-right',
        },
        {
          key: 'totalValue', label: 'Valoare', thClass: 'col-numeric-value', class: 'text-right',
        },
        {
          key: 'actions', label: '', thClass: 'col-numeric-value', class: '',
        },
      ],
      items: [],

      preventEnterJump: false,
    };
  },

  computed: {
    ...mapState(['xhrRequestRunning', 'lastRoute']),
    shouldShowQuantityWarning() {
      return this.currentlyEditedLine.cantitate <= 0;
    },
    shouldShowPriceWarning() {
      return this.currentlyEditedLine.pretUnitar <= 0;
    },
  },

  beforeRouteLeave(to, from, next) {
    if ((this.currentlyEditedLine || this.tableBusy)
      // eslint-disable-next-line no-alert
      && window.confirm('Modificările nu au fost finalizate, continuați navigarea?') === false) {
      return next(false);
    }
    return next();
  },

  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.isLoaded = false;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.productBundleId = to.params.productBundleId;
      vm.loadInitialData();
    });
  },

  async beforeRouteUpdate(to, from, next) {
    this.isLoaded = false;
    this.productBundleId = to.params.productBundleId;
    try {
      await this.loadInitialData();
    } finally {
      next();
    }
  },
  // Need to use this to allow Vue HMR to work
  created() {
    this.productBundleId = this.providedproductBundleId;
    this.loadInitialData();
  },
  filters: {
    displayAsDecimal(value, decimals = 2) {
      return `${new BigNumber(value).decimalPlaces(decimals).toString()}`;
    },
  },
  methods: {
    ...mapActions([
      'performProductBundleLinesLookup',
      'performProductBundleDetailsQuery',
      'performProductBundleItemsSelectionLookup',
      'performProductBundleLineSubmission',
      'performProductBundleUpdate',
      'performProductBundleLineDelete',
    ]),
    selectAll(evt) {
      evt.target.select();
    },

    toBigNumber(value) {
      return new BigNumber(value);
    },

    async saveHeader() {
      try {
        await this.performProductBundleUpdate({
          setProduseID: this.productBundleId,
          codSetProduse: this.productBundleDetails.codSetProduse,
          denumire: this.productBundleDetails.denumire,
          descriere: this.productBundleDetails.descriere,
          esteActiv: this.productBundleDetails.esteActiv,
        });

        toasts.success('Poziția a fost salvată');
      } catch (ex) {
        toasts.error(ex.response.data);
      }
    },

    async beginEditLine(row) {
      // Clean up
      this.currentlyEditedLine = null;
      this.currentProductBundleLine = null;

      // Set up current row based on the given row object
      if (row) {
        const rowItem = row.item;

        this.currentlyEditedLine = {
          linieSetProduseID: rowItem.linieSetProduseID,
          produsInternalID: rowItem.produsInternalID,
          setProduseID: rowItem.setProduseID,
          pretUnitar: rowItem.pretUnitar,
          cantitate: rowItem.cantitate,
          denumire: rowItem.denumire,
        };

        this.currentProductEntry = {
          ...this.currentlyEditedLine,
        };
      } else {
        this.currentlyEditedLine = {
          produsInternalID: null,
          setProduseID: this.productBundleDetails.setProduseID,
          pretUnitar: 0,
          cantitate: 0,
          denumire: '',
        };

        this.items.push(this.currentlyEditedLine);

        // Guard against component being unmounted
        if (this.$refs.productBundleLinesTable) {
          this.$refs.productBundleLinesTable.refresh();
        }
      }

      // Focus on product code input if working on a new row
      if (!row) {
        this.$nextTick(() => {
          jump(this.$refs.produsInternalID.$el);
          this.$refs.produsInternalID.$el.focus();
        });
      }
    },

    focusRef(refId) {
      // Add a bogus check to return early
      if (4 % 2 === 0) {
        return;
      }

      if (this.preventEnterJump) {
        this.preventEnterJump = false;
      }

      if (!this.$refs[refId]) {
        // eslint-disable-next-line no-console
        console.error(`Ref ${refId} not found!`);
      }

      const elem = this.$refs[refId].$el || this.$refs[refId];

      this.$nextTick(() => {
        elem.focus();
        if (typeof elem.select === 'function') {
          elem.select();
        }
      });
    },

    limitText(count) {
      return `și ${count} alte înregistrări`;
    },

    rowClass(item/* , type */) {
      if (!item || !this.currentlyEditedLine) return undefined;
      if (item.produsInternalID === this.currentlyEditedLine.produsInternalID) return 'table-primary';
      return undefined;
    },

    currentProductBundleLineSelected(selection) {
      this.currentProductBundleLineLookupValues = [];
      if (this.currentlyEditedLine) {
        if (selection) {
          this.items.filter((item) => item.linieSetProduseID === this.currentlyEditedLine.linieSetProduseID)[0].produsInternalID = selection.produsInternalID;
        }

        this.currentlyEditedLine.produsInternalID = selection ? selection.produsInternalID : null;
        this.currentlyEditedLine.denumire = selection ? selection.denumire : null;
      }
    },

    produsInternalID() {
      const shouldRefreshTable = !this.currentlyEditedLine.linieSetProduseID;
      if (shouldRefreshTable) {
        this.items.splice(this.items.findIndex((productBundlesRow) => !productBundlesRow.linieSetProduseID), 1);
      }

      this.currentlyEditedLine = null;
      this.currentProductEntry = null;
    },

    async loadInitialData() {
      if (!this.productBundleId) {
        return;
      }

      let result;
      let errorState = false;
      try {
        result = await Promise.all([
          this.performProductBundleDetailsQuery({ productBundleId: this.productBundleId }),
          this.performProductBundleLinesLookup({ productBundleId: this.productBundleId }),
        ]);
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);
        errorState = true;

        result = [];
      }

      this.productBundleDetails = result.length && result[0];
      this.productBundleDetails.esteActiv = String(this.productBundleDetails.esteActiv);
      this.items = result.length && result[1];

      if (errorState) {
        return;
      }

      if (result) {
        this.isLoaded = true;
      }
    },

    async asyncLookupProductBundleLines(lookupTerm) {
      if (!lookupTerm || lookupTerm.length < 3) {
        return;
      }

      this.currentProductBundleLineLookupValuesLoading = true;
      this.currentProductBundleLineLookupValues = [];
      try {
        this.currentProductBundleLineLookupValues = (await this.performProductBundleItemsSelectionLookup({
          lookupTerm,
          productBundleId: this.productBundleId,
        })).items.map((item) => ({
          produsInternalID: item.internalID,
          codProdus: item.codProdus,
          denumire: item.denumire,
          pretReferinta: item.pretReferinta,
          categorieProdus: item.categorieProdus,
          linieSetProduseID: item.linieSetProduseID,
        }));
      } catch (ex) {
        // TODO: show some notification to the user
        // eslint-disable-next-line no-console
        console.error(ex);
      }
      this.currentProductBundleLineLookupValuesLoading = false;
    },

    async editRow() {
      this.tableBusy = false;

      if (!this.currentProductEntry) {
        toasts.error('Selecteaza un produs');
        return;
      }

      if (this.shouldShowQuantityWarning) {
        toasts.error('Cantitate incorectă!');
        return;
      }

      if (this.shouldShowPriceWarning) {
        toasts.error('Preț incorect!');
        return;
      }

      const payload = {
        linieSetProduseID: this.currentlyEditedLine.linieSetProduseID,
        produsInternalID: this.currentlyEditedLine.produsInternalID,
        setProduseID: this.currentlyEditedLine.setProduseID,
        pretUnitar: this.currentlyEditedLine.pretUnitar,
        cantitate: this.currentlyEditedLine.cantitate,
      };
      try {
        await this.performProductBundleLineSubmission(payload);
        await this.loadInitialData();
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex.response);
        toasts.error(ex.response.data);
        return;
      } finally {
        this.tableBusy = false;
      }

      this.currentlyEditedLine = null;
      this.currentProductBundleLine = null;
      this.currentProductEntry = null;

      toasts.success('Poziția a fost salvată');
    },

    async deleteRow(row) {
      this.tableBusy = true;

      this.performProductBundleLineDelete({ linieSetProduseID: row.item.linieSetProduseID });
      this.items = this.items.filter((item) => item.linieSetProduseID !== row.item.linieSetProduseID);

      this.tableBusy = false;

      toasts.success('Poziția a fost ștearsă');
    },
  },
};
</script>
