<template>
  <b-container fluid>
    <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
      <h2>Seturi produse</h2>
      <b-button-toolbar class="pb-3">
        <b-button class="mx-1 p-2" id="createProductBundleToolbarButton" to="/product-bundle/create" variant="primary" v-b-tooltip.hover><i class="fas fa-plus-square" /><span class="d-none d-md-inline ml-1">Set nou</span></b-button>

        <b-tooltip target="createProductBundleToolbarButton" placement="topleft" custom-class="d-md-none">Set nou</b-tooltip>
      </b-button-toolbar>
    </div>
    <div class="container-fluid">
      <div class="row">
        <div class="col-sm">
        <b-pagination-nav :disabled="tableBusy" align="center" v-if="numPages > 1" :link-gen="paginationLinkGen" :number-of-pages="numPages" v-model="currentPage" use-router></b-pagination-nav>
        </div>
      </div>
    </div>
    <b-table
      :show-empty="!tableBusy"
      small
      striped
      hover
      empty-text="Nu este înregistrat niciun set de produse"
      :busy.sync="tableBusy"
      :items="items"
      :fields="tableFields"
      :sort-by.sync="orderBy"
      :sort-desc.sync="sortDesc"
      :no-local-sorting="true">
      <template v-slot:cell(esteActiv)="row">
        <div v-if="row.item.esteActiv">
          Da
        </div>
        <div v-else>
          Nu
        </div>
      </template>
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          :to="{name: 'productBundleDetails', params: { productBundleId: row.item.setProduseID } }"
          v-b-tooltip.hover title="Vezi set produse">
         <i class="fas fa-eye" />
        </b-button>
        <b-button
          v-if="row.item.esteActiv"
          size="sm"
          v-on:click="archive(row.item.setProduseID)"
          v-b-tooltip.hover title="Arhiveaza"
          class="ml-1">
         <i class="fas fa-archive"/>
        </b-button>
        <b-button
          v-if="!row.item.esteActiv"
          size="sm"
          v-on:click="dearchive(row.item.setProduseID)"
          v-b-tooltip.hover title="Dezarhiveaza"
          class="ml-1">
         <i class="fas fa-archive"/>
        </b-button>
      </template>
    </b-table>
    <b-pagination-nav :disabled="tableBusy" align="center" v-if="numPages > 1" :link-gen="paginationLinkGen" :number-of-pages="numPages" v-model="currentPage" use-router></b-pagination-nav>
  </b-container>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  name: 'ProductBundles',
  components: {
  },
  data() {
    return {
      currentPage: 1,
      numItems: 0,
      numPages: 1,
      perPage: 20,
      orderBy: 'codSetProduse',
      sortDesc: true,
      queryTerm: null,
      defaultFilters: {
        statusCode: {
          val: [],
          op: 'IN',
        },
        hasIssuedInvoices: {
          val: null,
          op: '=',
        },
      },
      tableFields: [
        {
          key: 'codSetProduse',
          label: 'Cod set',
          sortable: true,
        },
        {
          key: 'denumire',
          label: 'Denumire',
          sortable: true,
        },
        {
          key: 'numarProduse',
          label: 'Nr. prod.',
        },
        {
          key: 'valoare',
          label: 'Valoare',
        },
        {
          key: 'actions',
          label: '',
        },
      ],
      items: [],
      tableBusy: false,
      isCreatedByLookupRunning: false,
      orderImport: {
        clientCode: null,
        file: null,
      },
      performingLookup: false,
    };
  },
  watch: {
    orderBy() {
      this.resetPageAndLookup();
    },
    sortDesc() {
      this.resetPageAndLookup();
    },
  },
  computed: {
    itemsAvailable() { return this.items.length; },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.currentPage = to.query.page || 1;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.perPage = to.query.perPage || 20;
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.orderBy = to.query.orderBy || 'codSetProduse';
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.sortDesc = !to.query.orderByDirection || to.query.orderByDirection === 'd';
      // eslint-disable-next-line no-param-reassign,no-mixed-operators
      vm.queryTerm = to.query.queryTerm || null;
      // We don't want to save the filters as we've just loaded some
      vm.performLookupInternal(false);
    });
  },
  async beforeRouteUpdate(to, from, next) {
    this.currentPage = to.query.page || 1;
    this.perPage = to.query.perPage || this.perPage || 20;
    this.orderBy = to.query.orderBy || 'codSetProduse';
    this.sortDesc = !to.query.orderByDirection || to.query.orderByDirection === 'd';
    this.queryTerm = to.query.queryTerm || null;
    try {
      await this.performLookupInternal();
    } finally {
      next();
    }
  },
  methods: {
    ...mapActions([
      'performProductBundlesQuery',
      'performProductBundleSetStatus',
    ]),

    resetPageAndLookup() {
      this.currentPage = 1;

      this.performLookup();
    },

    async archive(productBundleID) {
      try {
        await this.performProductBundleSetStatus({ productBundleId: productBundleID, shouldArchive: true });
        this.performLookupInternal();
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
    },

    async dearchive(productBundleID) {
      try {
        await this.performProductBundleSetStatus({ productBundleId: productBundleID, shouldArchive: false });
        this.performLookupInternal();
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
    },

    async performLookup() {
      try {
        this.$router.push(this.paginationLinkGen(this.page));
      } catch (ex) {
        // eslint-disable-next-line no-console
        console.error(ex);
      }
    },

    paginationLinkGen(page) {
      return {
        name: 'productBundle',
        query: this.buildQueryObject(page),
      };
    },

    buildQueryObject(page) {
      return {
        page,
        perPage: this.perPage,
        orderBy: this.orderBy,
        orderByDirection: this.sortDesc ? 'd' : 'a',
        queryTerm: this.queryTerm ? this.queryTerm : undefined,
      };
    },

    async performLookupInternal() {
      this.tableBusy = true;
      this.performingLookup = true;

      let result;
      try {
        result = await this.performProductBundlesQuery({
          page: this.currentPage,
          perPage: this.perPage,
          orderBy: this.orderBy,
          orderByDirection: this.sortDesc ? 'd' : 'a',
          queryTerm: this.queryTerm ? this.queryTerm : undefined,
        });
      } catch (err) {
        // eslint-disable-next-line no-console
        console.error(err);

        result = {
          items: [],
          count: 0,
          limit: this.perPage,
          pages: 0,
        };
      }

      this.items = result.items;
      this.numItems = result.count;
      this.perPage = result.limit;
      // Avoid a validation error on the number of pages if we get back zero pages (no results)
      this.numPages = result.pages || 1;

      this.tableBusy = false;
      this.performingLookup = false;
    },
  },
};
</script>
