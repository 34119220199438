<template>
  <b-container fluid>
    <div>
      <div class="d-flex justify-content-between flex-wrap flex-md-nowrap">
        <h2>Adăugare set produse</h2>
        <b-button-toolbar class="pb-3">
          <super-button class="mx-1 p-2" id="navigateBackToolbarButton" :to="lastRoute || { name: 'productBundle' }"><i class="fas fa-caret-left"/><span class="d-none d-lg-inline ml-1">Înapoi</span></super-button>
        </b-button-toolbar>

        <b-tooltip target="navigateBackToolbarButton" placement="topleft" custom-class="d-lg-none">Înapoi</b-tooltip>
      </div>

      <b-card>
        <div slot="header">
          Detalii set produse nou&nbsp;
        </div>
        <div class="row">
          <b-form-group label="Cod set" class="col-xs-12 col-md-4 bundle_label_text_left">
            <b-input v-model="codSetProduse" placeholder="Introdu codul"></b-input>
          </b-form-group>

          <b-form-group label="Denumire set" class="col-xs-12 col-md-6 bundle_label_text_left">
            <b-input v-model="denumire" placeholder="Introdu denumirea"></b-input>
          </b-form-group>

          <b-form-group label="Este activ" class="col-xs-12 col-md-2 bundle_label_text_left">
            <b-form-select v-model="esteActiv" :options="esteActivOptions"></b-form-select>
          </b-form-group>

          <b-form-group label="Descriere set" class="col-12 bundle_label_text_left">
            <b-form-textarea v-model="descriere" :rows="3" :max-rows="6"></b-form-textarea>
          </b-form-group>
        </div>
        <b-button class="btn btn-primary" v-on:click="save">Adaugă</b-button>
      </b-card>
    </div>
  </b-container>
</template>

<style>
.bundle_label_text_left {
    text-align: initial;
}
</style>

<script>
import { mapState, mapActions } from 'vuex';
import toasts from '@/services/toasts';

export default {
  name: 'CreateProductBundle',
  components: {
  },
  computed: {
    ...mapState(['xhrRequestRunning', 'lastRoute']),
  },
  data() {
    return {
      codSetProduse: '',
      denumire: '',
      descriere: '',
      esteActiv: true,
      esteActivOptions: [
        { value: true, text: 'Da' },
        { value: false, text: 'Nu' },
      ],
    };
  },
  methods: {
    ...mapActions([
      'performProductBundleCreate',
    ]),
    async save() {
      try {
        if (this.codSetProduse === '') {
          toasts.error('Adaugă un cod pentru setul de produse');
          return;
        }
        if (this.denumire === '') {
          toasts.error('Adaugă o denumire');
          return;
        }
        if (this.descriere === '') {
          toasts.error('Adaugă o descriere');
          return;
        }

        const result = await this.performProductBundleCreate({
          codSetProduse: this.codSetProduse,
          denumire: this.denumire,
          descriere: this.descriere,
          esteActiv: this.esteActiv,
        });

        this.$router.push({
          name: 'productBundleDetails',
          params: {
            productBundleId: result,
          },
        });
      } catch (ex) {
        toasts.error(ex.response.data);
        // eslint-disable-next-line no-console
        console.error(ex);
      }
    },
  },
};
</script>
